/*All Pages*/
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/*Navbar*/

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #FF5E5E;
}

.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  color: white;
  background-color: #FF5E5E;
  border-radius: 8px;
}
.navbar a:hover {
  background: #ff375a;
}
.content {
  max-width: 85%;
  margin: 40px auto;
  margin-top: -80px;
  padding: 20px;
  border-color: 1px red;
}

/* ************* */


/*    Home   */


.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 10px !important;
}

.home-section {
  margin-top: 60px;
  position: relative;
  z-index: -1;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 3rem 0 0rem !important;
  color: #414141;
  text-align: left;
}

.heading-description {
  margin-left: 20px !important;
}
.heading-type {
  text-align: "center";
  margin-top: 20px;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 20px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 20px !important;
}

.main-name {
  color: #414141;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #414141 !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #414141 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 600 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  /* padding-bottom: 70px !important; */
}

.home-about-description {
  color: #414141 !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: #414141 !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 60px !important;
  height: 60px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: #FF5E5E !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  border: 1px solid #343e47;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #5b585c;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #FF5E5E;
}

.home-social-icons:hover {
  color: #414141;
  box-shadow: 0 0 5px #FF5E5E;
  text-shadow: 0 0 2px #FF5E5E;
}

.social-icons {
  display: inline-block !important;
  padding-left: 15px;
  height: 60px;
}

.icon-colour {
  color: #ffffff !important;
}

.social-icon {
  margin-top: 10px;
}


.profile-pic {
  border-radius: 50%;
  height: 80%;
  width: 90%;
  margin-top: 12%;
  margin-left: auto;
  margin-right: auto; /* Centers the image horizontally */
  display: block; /* Ensures the image respects margin auto */
}

@media (max-width: 767px) {
  .profile-pic {
    border-radius: 50%;
    height: 80%;
    width: 90%;
    margin-top: 12%;
    margin-left: 25%;
  }
}
/* ************* */

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #ff5269;
  bottom: 0 !important;
}
.footer-copywright {
  text-align: center !important;
  margin-top: 20px;
}

.footer-body {
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #ff5269 !important;
  font-weight: bold;
}

.footer-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 60px !important;
  height: 60px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: #ff5269 !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  border: 1px solid #ff5269;
}

.footer-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #5b585c;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.footer-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #ff5269;
}
.footer-social-icons:hover {
  color: #414141;
  box-shadow: 0 0 5px #ff5269;
  text-shadow: 0 0 2px #ff5269;
}

/*   Projects  */
/*   ****    */




.project-section {
  margin-top: 60px;
  position: relative !important;
  padding-top: 100px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  width: 50%;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px #ffb1b1 !important;
  color: #FF5E5E !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px #fc4444 !important;
}

.project-heading {
  color: #414141 !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  padding-bottom: 15px;
}



/*   Resume  */
/*   ****    */
.resume{
  margin: 15% auto;
}

.resume-pic {
  height: 100%;
  width: 65%;
  margin: 5% auto;
  display: flex;
  justify-content: center;
}

.download-button{
  max-width: 300px;
  display: flex;
  justify-content: center;
  margin:auto;
  background-color: #ff4437;
  border-color: #ff375a;
}

.button-spacing{
  margin-top: 10px;
}
@media (max-width: 767px) {
  .resume-pic {
    height: 100%;
    width: 65%;
    margin: 5% auto;
  }
}

